<template>
  <KPage>
    <v-row class="mx-0 my-3" v-if="supplies">
      <!-- Condomínios -->
      <v-col cols="12">
        <SectionHeader
          title="Condomínios"
          :actions="[
            {
              icon: 'mdi-refresh',
              label: 'Atualizar',
              eventName: 'click:refresh',
            },
          ]"
          @click:refresh="refresh"
        />
      </v-col>
      <v-col class="pa-0 mb-4" v-for="item in condominiums" :key="item.title">
        <CardInfo v-bind="item" />
      </v-col>

      <!-- Solicitações de Coleta -->
      <v-col cols="12">
        <SectionHeader title="Solicitações de Coleta" />
      </v-col>
      <v-col
        class="pa-0 mb-4"
        v-for="item in collect_requests"
        :key="item.title"
      >
        <CardInfo v-bind="item" />
      </v-col>

      <!-- Abastecimentos -->
      <v-col cols="12">
        <SectionHeader title="Abastecimentos" />
      </v-col>
      <v-col class="pa-0 mb-4" v-for="item in supplies" :key="item.title">
        <CardInfo v-bind="item" />
      </v-col>
    </v-row>

    <SpeedDial
      :actions="speedDialActions"
      @click:add_condominium="onAddCondominiumClick"
      @click:batches="onBatchesClick"
      @click:add_supply="onAddSupplyClick"
    />
  </KPage>
</template>

<script>
import KPage from "@/components/KPage";
import SectionHeader from "@/components/SectionHeader";
import SpeedDial from "@/components/SpeedDial.vue";

import CardInfo from "@/components/CardInfo";

export default {
  name: "collect-dashboard",

  components: {
    KPage,
    SectionHeader,
    CardInfo,
    SpeedDial
  },

  data() {
    return {
      speedDialActions: [
        {
          eventName: "click:add_condominium",
          label: "Novo Condomínio",
          icon: "mdi-city-variant",
          color: "blue"
        },
        {
          eventName: "click:batches",
          label: "Novo Lote de Solicitações",
          icon: "mdi-plus-box-multiple",
          color: "pink"
        },
        {
          eventName: "click:add_supply",
          label: "Novo Abastecimento",
          icon: "mdi-gas-cylinder",
          color: "indigo"
        }
      ]
    };
  },

  computed: {
    dashboard() {
      return this.$store.getters["collect_dashboard/all"];
    },
    // Condomínios
    condominiums() {
      // const dashboard = this.$store.getters["collect_dashboard/all"];
      const dashboard = this.dashboard;
      return [
        {
          icon: "mdi-city-variant",
          label: "Condomínios",
          value: dashboard.condominiums,
          to: "/collect/condominiums"
        },
        {
          icon: "mdi-city-variant",
          label: "Contratos de consumo",
          value: dashboard.contract
        },
        {
          icon: "mdi-plus",
          label: "Novo condomínio",
          // value: '',
          to: "/collect/condominiums/add"
        }
      ];
    },

    // Solicitaçõe de Coleta
    collect_requests() {
      // const dashboard = this.$store.getters["collect_dashboard/all"];
      const dashboard = this.dashboard;
      return [
        {
          icon: "mdi-clipboard-list-outline",
          label: "Solicitações de Coleta",
          value: dashboard.collectRequests,
          to: "/collect/collections"
        },
        {
          icon: "mdi-clipboard-list-outline",
          label: "Pendentes",
          value: dashboard.pendentCollectRequests
        },
        {
          icon: "mdi-plus",
          label: "Novo lote de solicitações",
          to: "/collect/collections/batch"
        }
      ];
    },

    // Abastecimentos
    supplies() {
      // const result = this.$store.getters["collect_dashboard/all"];
      const dashboard = this.dashboard;
      if (!dashboard || !dashboard.pendent_supplies) return {};
      return [
        {
          label: "Abastecimentos",
          value: dashboard.supplies,
          icon: "mdi-gas-cylinder",
          to: "/collect/supplies"
        },
        {
          label: "Boletos Pendentes",
          // value: dashboard.pendent_supplies.sum,
          icon: "mdi-alert-outline",
          // type: "currency",
          to: "/financials/search?status_id=2&module=supply"
        },
        {
          label: "Novo abastecimento",
          // value: dashboard.expiring_supplies.sum,
          icon: "mdi-plus",
          // type: "currency",
          to: "/collect/supplies/add"
        }
        // {
        //   label: "Vencendo em 5 dias (R$)",
        //   value: dashboard.expiring_supplies.sum,
        //   icon: "mdi-alert",
        //   type: "currency"
        //   // to: "/collect/supplies"
        // }
      ];
    }
  },

  methods: {
    onAddCondominiumClick() {
      this.$router.push("/collect/condominiums/add");
    },

    onAddSupplyClick() {
      this.$router.push("/collect/supplies/add");
    },

    onBatchesClick() {
      this.$router.push("/collect/collections/batch");
    },

    async refresh() {
      try {
        this.$loading.show();
        await Promise.all([
          this.$store.dispatch("condominiums/all"),
          this.$store.dispatch("collect_dashboard/refresh")
        ]);
        this.$loading.hide();
      } catch (error) {
        this.$message.serverError(error);
        this.$loading.hide();
      }
    }
  },

  mounted() {
    this.refresh();
  }
};
</script>

<style>
</style>
