var render = function render(){var _vm=this,_c=_vm._self._c;return _c('KPage',[(_vm.supplies)?_c('v-row',{staticClass:"mx-0 my-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('SectionHeader',{attrs:{"title":"Condomínios","actions":[
          {
            icon: 'mdi-refresh',
            label: 'Atualizar',
            eventName: 'click:refresh',
          },
        ]},on:{"click:refresh":_vm.refresh}})],1),_vm._l((_vm.condominiums),function(item){return _c('v-col',{key:item.title,staticClass:"pa-0 mb-4"},[_c('CardInfo',_vm._b({},'CardInfo',item,false))],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('SectionHeader',{attrs:{"title":"Solicitações de Coleta"}})],1),_vm._l((_vm.collect_requests),function(item){return _c('v-col',{key:item.title,staticClass:"pa-0 mb-4"},[_c('CardInfo',_vm._b({},'CardInfo',item,false))],1)}),_c('v-col',{attrs:{"cols":"12"}},[_c('SectionHeader',{attrs:{"title":"Abastecimentos"}})],1),_vm._l((_vm.supplies),function(item){return _c('v-col',{key:item.title,staticClass:"pa-0 mb-4"},[_c('CardInfo',_vm._b({},'CardInfo',item,false))],1)})],2):_vm._e(),_c('SpeedDial',{attrs:{"actions":_vm.speedDialActions},on:{"click:add_condominium":_vm.onAddCondominiumClick,"click:batches":_vm.onBatchesClick,"click:add_supply":_vm.onAddSupplyClick}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }